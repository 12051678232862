.projects-section {
  padding: 100px 0;
  background-image: url('../images/bg_projects-3.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;

  .title {
    font-weight: 100;
    color: #fff;
    font-size: 2.5rem;
    text-transform: uppercase;
  }

  .desc {
    font-weight: 300;
    color: #fff;
    font-size: 1.2rem;
  }

  .latest-projects {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    // background-color: #fff;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 900px) {
      grid-template-columns: repeat(4, 1fr);
    }

    li {
      position: relative;
      overflow: hidden;

      img {
        width: 100%;
      }

      a {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: rgb(240, 240, 240);
        font-size: 1.2rem;
        width: 100%;
        height: 100%;
        text-align: center;
        vertical-align: middle;
        padding: 30px 15px;
        text-shadow: 1px 1px 2px rgba(1, 5, 8, 0.4);
        line-height: 1.6rem;
        background-color: rgba(0, 0, 0, 0.459);
      }
    }
  }
}
