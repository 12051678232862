.top-menu {
  background-color: $light-pink;
  border-bottom: 0.3rem solid $vks-green;

  .menu {
    &__links {
      font-size: 13px;
      color: #888;
      transform: scale(1);

      //    &:hover {
      //        color: #24262b;
      //    }
    }
  }
}

.main-nav {
  height: 5rem;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;

  .logo {
    width: 120px;
  }

  .navbar-nav a {
    text-transform: uppercase;
  }

  .nav-item,
  .dropdown-item {
    font-weight: 300;
    padding-right: 1.2rem;
  }
}
