$light-pink: #f2f8fc;
$vks-green: #93ab30;
$vks-green-light: #bfd85a;
$vks-red: #f65c38;
$blue-light: #a4becd;
$black: #000;
$dark: #121315;
$text-dark: #222;

// color swatch
$red: #ec5242;
$dark: #272a31;
$gray: #d3d3d3;
