* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  text-decoration: none;
  list-style: none;
  color: $dark;
}

// html {
//   font-size: 62.5%;

//   @media (min-width: 768px) {
//     font-size: 72.5%;
//   }
// }

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  box-sizing: border-box;
}

a {
  color: $dark;
  cursor: pointer !important;
}

.page-space {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
