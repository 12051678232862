.feature-section {
  padding: 100px 0;
  text-align: center;
  background-image: url('../images/bg_about.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;

  .features {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 900px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .feature-item {
    padding: 10px;
    font-size: 1rem;
    font-weight: 300;

    &__img {
      border-radius: 50%;
    }

    .title {
      text-transform: uppercase;
      font-size: 1.1rem;
      color: $dark;
    }
  }
}
