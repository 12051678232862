.page {
  padding: 20px 5px;
}

.mission-section {
  background: url('../images/bg_vision.jpeg') no-repeat;
  background-size: cover;
}

.about-section {
  background: url('../images/bg_about.jpg') no-repeat;
  background-size: cover;
}

.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  }
}
